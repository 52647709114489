import React from "react";
import { BarChart } from "@tremor/react";

class SimpleBarChart extends React.Component {
  render() {
    const { chartData, dataFormatter } = this.props;

    return (
      <BarChart
        className="mt-6"
        data={chartData}
        index="name"
        categories={["signUps"]}
        colors={["blue"]}
        valueFormatter={dataFormatter}
        yAxisWidth={48}
      />
    );
  }
}

export default SimpleBarChart;
