import "../App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="text-3xl font-bold">Hello from Ahwoo!</h1>
      </header>
    </div>
  );
}

export default App;
