import React from "react";
import { Card, Text, Metric, LineChart } from "@tremor/react";

class SimpleCard extends React.Component {
  dataFormatter = (number) =>
    `${Intl.NumberFormat("us").format(number).toString()}%`;

  render() {
    const { title, metricValue } = this.props;

    return (
      <Card className="max-w-lg mx-auto">
        <Text>{title}</Text>
        <Metric>{metricValue}</Metric>
        {/* <LineChart
          className="mt-6"
          data={chartdata}
          index="year"
          categories={["Export Growth Rate", "Import Growth Rate"]}
          colors={["emerald", "gray"]}
          valueFormatter={dataFormatter}
          yAxisWidth={40}
        /> */}
      </Card>
    );
  }
}

export default SimpleCard;
