import React from "react";
import SimpleCard from "../blocks/SimpleCard.js";
import SimpleBarChart from "../blocks/SimpleBarChart.js";
import MarketingSpendTabPanel from "../blocks/MarketingSpendTabPanel.js";

import {
  Grid,
  TabGroup,
  TabPanel,
  TabList,
  Tab,
  TabPanels,
  Title,
  Text,
} from "@tremor/react";

class MarketingSpendDashboard extends React.Component {
  // cut
  formatSpend = (number) => {
    return "$" + Intl.NumberFormat("us").format(number).toString();
  };
  formatPercentage = (numerator, denominator) => {
    return (numerator / denominator).toFixed(4) + "%";
  };
  render() {
    const sampleChartdata = [
      {
        name: "Youtube",
        signUps: 2488,
        spend: 12564,
        shows: 10000,
      },
      {
        name: "Instagram",
        signUps: 1445,
        spend: 16530,
        shows: 15425,
      },
      {
        name: "TikTok",
        signUps: 3400,
        spend: 14053,
        shows: 13205,
      },
    ];

    // Create the Overview object
    const totalSignUps = sampleChartdata.reduce(
      (sum, data) => sum + data.signUps,
      0
    );
    const totalSpend = sampleChartdata.reduce(
      (sum, data) => sum + data.spend,
      0
    );
    const totalShows = sampleChartdata.reduce(
      (sum, data) => sum + data.shows,
      0
    );
    //
    const overviewObject = {
      name: "Overview",
      signUps: totalSignUps,
      spend: totalSpend,
      shows: totalShows,
    };

    const sampleDataFormatter = (number) => {
      return Intl.NumberFormat("us").format(number).toString();
    };

    return (
      <div className="px-12 py-12">
        <Title>Marketing Spend Dashboard</Title>
        <Text>
          This dashboard is a quick snippet into your marketing spend.
        </Text>

        <TabGroup className="mt-6">
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Youtube</Tab>
            <Tab>Instagram</Tab>
            <Tab>TikTok</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mt-6">
                <SimpleCard
                  title="Total Spend For Last 30 Days"
                  metricValue={this.formatSpend(overviewObject.spend)}
                />
                <SimpleCard
                  title="Sign Ups For Last 30 Days"
                  metricValue={overviewObject.signUps}
                />
                <SimpleCard
                  title="Sign Up Rate For Last 30 Days"
                  metricValue={this.formatPercentage(
                    overviewObject.signUps,
                    overviewObject.shows
                  )}
                />
              </Grid>
              <div className="mt-8 hidden sm:block">
                <SimpleBarChart
                  chartData={sampleChartdata}
                  dataFormatter={this.dataFormatter}
                />
              </div>
            </TabPanel>
            {/* <MarketingSpendTabPanel
              chartData={overviewObject}
              dataFormatter={sampleDataFormatter}
            /> */}
            <MarketingSpendTabPanel
              chartData={sampleChartdata.find(
                (item) => item.name === "Youtube"
              )}
              dataFormatter={sampleDataFormatter}
            />
            <MarketingSpendTabPanel
              chartData={sampleChartdata.find(
                (item) => item.name === "Instagram"
              )}
              dataFormatter={sampleDataFormatter}
            />
            <MarketingSpendTabPanel
              chartData={sampleChartdata.find((item) => item.name === "TikTok")}
              dataFormatter={sampleDataFormatter}
            />
          </TabPanels>
        </TabGroup>
      </div>
    );
  }
}
export default MarketingSpendDashboard;
