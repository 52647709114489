// chartUtils.js
export function generateChartData(interval) {
  const platforms = ["Instagram", "YouTube", "TikTok"];
  const chartData = {};

  platforms.forEach((platform) => {
    const platformData = [];
    for (let day = 1; day <= interval; day++) {
      platformData.push({
        day: `Day ${day}`,
        "Spend per Day": getRandomSpend(),
        "Sign Ups per Day": getRandomClicks(),
      });
    }
    chartData[platform] = platformData;
  });

  return chartData;
}

function getRandomSpend() {
  return Math.floor(Math.random() * 500); // Generates a random spend value between 0 and 500
}

function getRandomClicks() {
  return Math.floor(Math.random() * 1000); // Generates a random number of clicks between 0 and 1000
}
