import React from "react";
import { TabPanel, Grid, LineChart } from "@tremor/react";
import SimpleCard from "../blocks/SimpleCard.js";
import { generateChartData } from "../data/DataFetcher.js";

class MarketingSpendTabPanel extends React.Component {
  formatSpend = (number) => {
    return "$" + Intl.NumberFormat("us").format(number).toString();
  };
  formatPercentage = (numerator, denominator) => {
    return "$" + (numerator / denominator).toFixed(4);
  };

  render() {
    const chartData = generateChartData(30);
    const instagramData = chartData["Instagram"];
    const { dataFormatter } = this.props;
    const totalSpend = instagramData.reduce(
      (total, entry) => total + entry["Spend per Day"],
      0
    );
    const totalSignups = instagramData.reduce(
      (total, entry) => total + entry["Sign Ups per Day"],
      0
    );
    const averageSignupCost = this.formatPercentage(totalSignups, totalSpend);

    console.log("totalSpend" + totalSpend);
    console.log("totalSignups" + totalSignups);

    return (
      <TabPanel>
        <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mt-6">
          <SimpleCard
            title="Total Spend For Last 30 Days"
            metricValue={this.formatSpend(totalSpend)}
          />
          <SimpleCard title="Sign Ups 30 Days" metricValue={totalSignups} />
          <SimpleCard
            title="Average Signup Cost For Last 30 Days"
            metricValue={averageSignupCost}
          />
        </Grid>
        <div className="mt-8 hidden sm:block">
          <LineChart
            className="mt-6"
            data={instagramData}
            index="year"
            categories={["Spend per Day", "Sign Ups per Day"]}
            colors={["emerald", "gray"]}
            valueFormatter={dataFormatter}
            yAxisWidth={40}
          />
        </div>
      </TabPanel>
    );
  }
}

export default MarketingSpendTabPanel;
